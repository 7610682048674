export class InitDeviceKeyArray {
    static readonly type = '[GenerationKey] InitDeviceKeyArray';
}

export class CreateDeviceKeyRow {
    static readonly type = '[GenerationKey] CreateDeviceKeyRow';
}

export class GetDeviceKeyRows {
    static readonly type = '[GenerationKey] GetDeviceKeyRows';
}

export class GetDeviceKey {
    static readonly type = '[GenerationKey] GetDeviceKey';

    constructor(public id: string) {}
}

export class RegenerationDeviceKey {
    static readonly type = '[GenerationKey] RegenerationDeviceKey';

    constructor(public id: string) {}
}

export class DeleteGenerationRow {
    static readonly type = '[GenerationKey] DeleteGenerationRow';

    constructor(public id: string) {}
}

export class DownloadKey {
    static readonly type = '[GenerationKey] DownloadKey';

    constructor(public key: string) {}
}

//
// export class SetRegistratorNames {
//     static readonly type = '[GenerationKey] SetRegistratorNames';
// }
